<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">            
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    <div class="content-onglets"></div>
                    
                    <!-- Section Detail Start -->

                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->   
                   
                    <!--detail_indication start-->
                    <Indication v-if="key == 'detail_indication' && item.show" />
                    <!--detail_indication end-->
                    
                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->                    
                    
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />    
                     <!-- detail_Conclusion end-->

                    <!-- evaluation_essais_clinique start--> 
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!-- evaluation_essais_clinique end-->
                    
                    <!-- detail_key_documents start -->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!-- detail_key_documents end -->

                    <!--detail_recommendations start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_recommendations' && item.show" >                          
                        <span v-html="data.ntcouncil['recommendations']" ></span>                          
                    </div>  
                    <!--detail_recommendations end-->

                    <!--detail_drug_description start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_drug_description' && item.show" >                          
                        <span v-html="data.ntcouncil['drug_description']" ></span>                          
                    </div>  
                    <!--detail_drug_description end-->                   

                    <!--detail_assessment_role_therapy start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_assessment_role_therapy' && item.show" >                          
                        <span v-html="data.ntcouncil['assessment_role_therapy']" ></span> 
                    </div>  
                    <!--detail_assessment_role_therapy end-->

                    <!--detail_summary start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_summary' && item.show" >                       
                          <table class="comparateur2 text-left border-top-gray">                   
                            <tr v-if="data.ntcouncil['summary_severity_condition']">
                                <td class="bold bg-white" width="30%"><strong>Severity of the condition</strong></td>
                                <td class="bg-white">{{data.ntcouncil['summary_severity_condition']}}</td>
                            </tr>
                            <tr v-if="data.ntcouncil['summary_effect_treatment_drug']">
                                <td class="bold bg-white" width="30%"><strong>Effect of the treatment with the drug</strong></td>
                                <td class="bg-white">{{data.ntcouncil['summary_effect_treatment_drug']}}</td>
                            </tr>    
                            <tr v-if="data.ntcouncil['summary_reliability_scientific_documentation']">
                                <td class="bold bg-white" width="30%"><strong>Reliability of the scientific documentation</strong></td>
                                <td class="bg-white">{{data.ntcouncil['summary_reliability_scientific_documentation']}}</td>
                            </tr>      
                            <tr v-if="data.ntcouncil['summary_rarity_condition']">
                                <td class="bold bg-white" width="30%"><strong>Rarity of the condition</strong></td>
                                <td class="bg-white">{{data.ntcouncil['summary_rarity_condition']}}</td>
                            </tr>     
                            <tr v-if="data.ntcouncil['summary_reliability_health_economic_assessment']">
                                <td class="bold bg-white" width="30%"><strong>Reliability of the Health Economic Assessment</strong></td>
                                <td class="bg-white">{{data.ntcouncil['summary_reliability_health_economic_assessment']}}</td>
                            </tr>          
                        </table>     
                    </div>  
                    <!--detail_summary end-->

                    <!--detail_severity_condition start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_severity_condition' && item.show" >                          
                        <span v-html="data.ntcouncil['severity_condition']" ></span> 
                    </div>  
                    <!--detail_severity_condition end-->

                    <!--detail_treatment_effectiveness start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_treatment_effectiveness' && item.show" >                          
                        <span v-html="data.ntcouncil['treatment_effectiveness']" ></span> 
                    </div>  
                    <!--detail_treatment_effectiveness end-->

                     <!--detail_scientific_documentation_reliability start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_scientific_documentation_reliability' && item.show" >                          
                        <span v-html="data.ntcouncil['scientific_documentation_reliability']" ></span> 
                    </div>  
                    <!--detail_scientific_documentation_reliability end-->

                    <!--detail_rarity_condition start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_rarity_condition' && item.show" >                          
                        <span v-html="data.ntcouncil['rarity_condition']" ></span> 
                    </div>  
                    <!--detail_rarity_condition end-->

                    <!--detail_treatment_cost_effectiveness start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_treatment_cost_effectiveness' && item.show" >                          
                        <span v-html="data.ntcouncil['treatment_cost_effectiveness']" ></span> 
                    </div>  
                    <!--detail_treatment_cost_effectiveness end-->

                    <!--detail_reliability_health_economic_assessment start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_reliability_health_economic_assessment' && item.show" >                          
                        <span v-html="data.ntcouncil['reliability_health_economic_assessment']" ></span> 
                    </div>  
                    <!--detail_reliability_health_economic_assessment end-->


                    <!-- Section Detail End -->
                </div>
            </template>

             <!-- Link Section Start-->       
           <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
            
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import Indication from '../Indication.vue'
import InformationGeneral from '../InformationGeneral.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'
import { url_prismaccess } from '@/app.config';

export default {
    name: 'ntcouncil',
    components : {
        LeftSection,
        Conclusion,
        TreatmentLine,
        LinkAgency,
        KeyDocument,
        EssaisClinique,
        Indication,
        InformationGeneral
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indication : { 'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},      
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true , 'bg_gray':false},
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_recommendations : {'title' : 'Recommendations', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_drug_description : {'title' : 'Drug Description', 'show' : true, 'enable' : false, 'orange_text':false },  
                detail_assessment_role_therapy : {'title' : 'The Assessment of the Role in Therapy', 'show' : true, 'enable' : false, 'orange_text':false },            
                detail_summary : {'title' : 'NT Council assessment of the drugs Summary', 'show' : true, 'enable' : false, 'orange_text':false },            
                detail_severity_condition : {'title' : 'Severity of the condition', 'show' : true, 'enable' : false, 'orange_text':false },            
                detail_treatment_effectiveness : {'title' : 'Treatment effectiveness', 'show' : true, 'enable' : false, 'orange_text':false },            
                detail_scientific_documentation_reliability : {'title' : 'Scientific Documentation Reliability', 'show' : true, 'enable' : false, 'orange_text':false },            
                detail_rarity_condition : {'title' : 'Rarity of the condition', 'show' : true, 'enable' : false, 'orange_text':false },            
                detail_treatment_cost_effectiveness : {'title' : 'Treatment cost-effectiveness', 'show' : true, 'enable' : false, 'orange_text':false },            
                detail_reliability_health_economic_assessment : {'title' : 'Reliability of the Health Economic Assessment', 'show' : true, 'enable' : false, 'orange_text':false },            
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true }, 

            }
        }
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents
    },
    created() {
        // condition to show check box in left section [enable]
        if (this.data && this.data.ntcouncil){

            if (this.data.ntcouncil['ma_date']){
                this.list_check_box['detail_information_general'].enable = true
            }
            if(this.data['indication_en']){ 
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }

            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
                                
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }                  
            if(this.data.ntcouncil['recommendations']){
                this.list_check_box['detail_recommendations'].enable = true
            }
            if(this.data.ntcouncil['drug_description']){
                this.list_check_box['detail_drug_description'].enable = true
            }
            if(this.data.ntcouncil['assessment_role_therapy']){
                this.list_check_box['detail_assessment_role_therapy'].enable = true
            }
            if(this.data.ntcouncil['summary_severity_condition'] || this.data.ntcouncil['summary_effect_treatment_drug'] || this.data.ntcouncil['summary_reliability_scientific_documentation'] || this.data.ntcouncil['summary_rarity_condition'] || this.data.ntcouncil['summary_reliability_health_economic_assessment'] ){
                this.list_check_box['detail_summary'].enable = true
            }
            if(this.data.ntcouncil['severity_condition']){
                this.list_check_box['detail_severity_condition'].enable = true
            }           
            if(this.data.ntcouncil['treatment_effectiveness']){
                this.list_check_box['detail_treatment_effectiveness'].enable = true
            }      
            if(this.data.ntcouncil['scientific_documentation_reliability']){
                this.list_check_box['detail_scientific_documentation_reliability'].enable = true
            }     
            if(this.data.ntcouncil['rarity_condition']){
                this.list_check_box['detail_rarity_condition'].enable = true
            }  
            if(this.data.ntcouncil['treatment_cost_effectiveness']){
                this.list_check_box['detail_treatment_cost_effectiveness'].enable = true
            }  
            if(this.data.ntcouncil['reliability_health_economic_assessment']){
                this.list_check_box['detail_reliability_health_economic_assessment'].enable = true
            }  
        }
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        FULL_BASE_URL(){
            return url_prismaccess
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }  
    },
}
</script>

<style>

</style>